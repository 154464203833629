import zafClient from 'store/api/base/zafClient';

export const DEFAULT_CONFIG =
  process.env.NODE_ENV !== 'production'
    ? {
        ENV: 'stg',
        ORDER_FIELD: 'ticket.customField:custom_field_360013454738',
        API_URL: `https://openapi.stg.manomano.com`,
        DNS: 'https://monechelle1612973969.zendesk.com',
        BO_CUSTOMER_SERVICE: 'https://bo-visitors.stg.manomano.com',
      }
    : {
        ENV: 'prd',
        ORDER_FIELD: 'ticket.customField:custom_field_23693907',
        API_URL: 'https://openapi.manomano.com',
        DNS: 'https://monechelle.zendesk.com',
        BO_CUSTOMER_SERVICE: 'https://bo-visitors.manomano.com',
      };

export const appConfig = {
  showBanner: true,
  APP_CDN: 'https://cdn.manomano.com',
  TOOLBOX: 'https://toolbox.manomano.com/login?seller_id=',
  BO_FILE:
    'https://bo.monechelle.com/messages/messagesadmin/getattachments?id=',
  ZAF_CLIENT: zafClient ? zafClient : window.opener?.client,
};
