// RTK Query
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

// API
import { msfApi } from 'store/api/services';
import Routes from 'store/api/routes';

// Types
import { APIRequestTypes, Service } from 'store/types/api';
import { IDeliveryResponse, RelayAddress } from 'store/types/delivery';

// Utils
import { isEmptyObject, isNil } from 'utils/validations';

const endpoints = (build: EndpointBuilder<Service, string, string>) => ({
  getDeliveryByOrderId: build[APIRequestTypes.QUERY]({
    query(arg: string) {
      const url = Routes.delivery.byOrderId.replace(':orderId', arg);

      return { url };
    },
    transformResponse: ({ content }: IDeliveryResponse) => {
      const { shipping_address, relay_address, ...rest } = content;

      const {
        firstname = '',
        lastname = '',
        address_line1 = '',
        city = '',
        country_iso = '',
        country = '',
        zipcode = '',
        ...shippingAddressInfo
      } = shipping_address ?? {};

      return {
        ...rest,
        // If we don't have the relay info, keep using the shipping info as fallback.
        shipping_address:
          isNil(relay_address) || isEmptyObject(relay_address)
            ? {
                ...shippingAddressInfo,
                address_line1,
                city,
                country_iso,
                country,
                zipcode,
                name: `${firstname} ${lastname}`,
                id: '',
              }
            : (relay_address as RelayAddress),
      };
    },
  }),
});

const deliveryService = msfApi.injectEndpoints({
  endpoints: endpoints,
  overrideExisting: false,
});

export const { useGetDeliveryByOrderIdQuery } = deliveryService;

export type DeliveryService = typeof deliveryService;

export default deliveryService;
