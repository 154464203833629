import { RootState } from 'store';
import { decodeJwt } from 'jose';
import * as Sentry from '@sentry/browser';
import { RequestArgs, RequestMethods } from 'store/types/api';
import { IServerError, UIError } from 'store/types/error';
import { errorHandler } from 'store/utils/error';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query';
import { appConfig } from 'config/cv';
import { ACCESS_TOKEN } from 'constants/api';
import { DEFAULT_LOCALE } from 'constants/global';

const zaf = appConfig.ZAF_CLIENT;

// Get user info from jwt token
export const getUserFromToken = () => {
  const token = sessionStorage.getItem(ACCESS_TOKEN);
  const decodedToken = decodeJwt(token ?? '');
  const { email = '', name = '' } = decodedToken ?? {};

  return {
    email,
    name,
    locale: DEFAULT_LOCALE,
  };
};

// Make API calls using zendesk client
export const ZdRequest = async (
  args: RequestArgs,
  { getState }: BaseQueryApi
) => {
  const { API_URL, DNS } = window.env;

  const { url, method = RequestMethods.GET, body = {} } = args;
  let { externalId = '', email = '' } =
    (getState() as RootState)?.userSlice ?? {};

  const shouldSendBody = method !== RequestMethods.GET;

  try {
    // A fallback in case we weren't able to get the data when the app was mounted
    if (shouldSendBody && (!externalId || !email)) {
      const zdUser = await zaf.get('currentUser');

      const { currentUser } = zdUser;

      if (!currentUser?.externalId && !currentUser?.email) {
        Sentry.captureException({
          title: 'Agent not found',
          data: JSON.stringify(zdUser),
        });
      }

      email = currentUser?.email ?? email;
      externalId = currentUser?.externalId ?? externalId;
    }

    const response = await zaf.request({
      url: `${API_URL}${url}`,
      type: method,
      secure: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: '{{setting.token}}',
        'x-mm-agent-external-id': externalId,
        'x-mm-agent-email': email,
        'Access-Control-Allow-Origin': DNS,
      },
      data: shouldSendBody
        ? JSON.stringify({
            agent_email: email,
            agent_id: externalId,
            ...body,
          })
        : null,
    });

    return { data: response };
  } catch (error: unknown) {
    return { error: errorHandler(error as IServerError, args) };
  }
};

// Makes API calls out of ZD client
export const fetchRequest = async (args: RequestArgs) => {
  const { url, method = RequestMethods.GET, body = {}, headers = {} } = args;
  const { API_URL } = window.env;
  const token = sessionStorage.getItem(ACCESS_TOKEN);
  const decodedToken = decodeJwt(token ?? '');

  try {
    const response = await fetch(`${API_URL}${url}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Accept: '*/*',
        ...headers,
      },
      body: Object.keys(body).length
        ? JSON.stringify({
            agent_email: decodedToken.email,
            ...body,
          })
        : null,
    });

    let json: { error?: IServerError } = {};

    if (response.status !== 204) {
      json = await response.json();
    }

    if (!response.ok) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return { error: errorHandler(json.error!) };
    }

    return { data: json };
  } catch (error: unknown) {
    Sentry.captureException(error, {
      tags: {
        url: args?.url,
        method: args?.method,
        body: JSON.stringify(args?.body ?? {}),
      },
    });

    return { error: error as UIError };
  }
};
