import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import reportWebVitals from './reportWebVitals';

// Router
import { HashRouter as Router } from 'react-router-dom';

// Redux
import { Provider } from 'react-redux';
import store from 'store';

// Components
import App from './App';
import ErrorBoundary, { ErrorComponent } from 'client/ui/ErrorBoundary';

// Constants
import { DEFAULT_CONFIG, appConfig } from 'config/cv';

// Config
import { initSentry } from 'config/sentry';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
fetch('/config.json')
  .then((response) => response.json())
  .then((config) => {
    window.env = config;

    if (
      process.env.NODE_ENV === 'production' &&
      (window.env.ENV as string).toLowerCase() === 'prd'
    ) {
      initSentry();
    }
  })
  .catch((e) => {
    // eslint-disable-next-line no-console
    console.warn(
      `Config file could not be load. Using default values. Error :${e}`
    );

    window.env = DEFAULT_CONFIG;
  })
  .finally(() => {
    const oidcConfig: AuthProviderProps = {
      authority: window.env['OIDC_AUTHORITY'] as string,
      client_id: window.env['OIDC_CLIENT_ID'] as string,
      redirect_uri: window.location.href,
      scope: 'openid email profile roles',
      response_type: 'code',
      loadUserInfo: true,
    };

    const AppRouter = (
      <Router>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>
    );

    root.render(
      <React.StrictMode>
        <ErrorBoundary errorComponent={<ErrorComponent />}>
          {/* We use OIDC auth only for the standalone app. No needed
        when going trhough ZD */}
          {!appConfig.ZAF_CLIENT ? (
            <AuthProvider {...oidcConfig}>{AppRouter}</AuthProvider>
          ) : (
            AppRouter
          )}
        </ErrorBoundary>
      </React.StrictMode>
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
