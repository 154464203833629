import { SearchCustomerOrderProductsResponse } from '../requesters/searchCustomerOrderProducts.contract';
import { CustomerOrderProduct } from '../models';
import { productImageMapper } from './productImageMapper';

export const searchCustomerOrderProductsMapper = ({
  content,
}: SearchCustomerOrderProductsResponse): CustomerOrderProduct[] => {
  return content.products.map((product) => ({
    id: product.product_order_id,
    quantity: product.quantity,
    title: product.title,
    image: productImageMapper(product.image),
    productId: product.product_id,
    mmId: product.mm_id,
  }));
};
