import * as Sentry from '@sentry/browser';
import { IServerError, UIError } from 'store/types/error';
import {
  cgNoTicketForOrderError,
  customerNotFoundMapper,
  outsideOpenError,
  sellerOrderError,
  ticketNotFoundMapper,
} from './errorMappers';
import { OUTSIDE_OPEN_ERROR } from 'constants/error';
import { RequestArgs } from 'store/types/api';

const defaultError: IServerError = {
  responseJSON: {
    error: {
      app_code: '',
      message: '',
    },
  },
  status: '',
  responseText: '',
  message: '',
};

/**
 * Matches the expected errors and returns an user friendly
 * message for each case
 */
const errorMappers: {
  match: (error: IServerError) => boolean;
  toUIError: (error?: IServerError) => UIError;
}[] = [
  {
    match: customerNotFoundMapper,
    toUIError: () => ({
      title: 'The information could not be loaded',
      message: 'This customer has been deleted due GDPR compliance',
    }),
  },
  {
    match: outsideOpenError,
    toUIError: () => ({
      title: OUTSIDE_OPEN_ERROR.status,
      message: OUTSIDE_OPEN_ERROR.responseText,
    }),
  },
  {
    match: cgNoTicketForOrderError,
    toUIError: (err) => ({
      title: 'Concrete guarantee could not be opened',
      message: err?.responseJSON?.error?.message?.replaceAll('"', '') ?? '',
    }),
  },
  {
    match: ticketNotFoundMapper,
    toUIError: () => ({
      title: 'Something went wrong',
      message: 'Ticket could not be found. Please try again',
    }),
  },
  {
    match: sellerOrderError,
    toUIError: () => ({
      title: 'Something went wrong',
      message: 'We could not load the order. Please try again',
    }),
  },
];

export const errorHandler: (
  err: IServerError,
  args?: RequestArgs
) => UIError = (err = defaultError, args) => {
  // Check if we already expect this error and we're mapping it in the UI side
  const mapper = errorMappers.find((mapper) => mapper.match(err));

  if (mapper) return mapper.toUIError(err);

  // If we do not expect it, log it into Sentry for monitoring purposes
  Sentry.captureException(err, {
    tags: {
      url: args?.url,
      method: args?.method,
      body: JSON.stringify(args?.body ?? {}),
    },
  });

  let error = {
    title: `Error: Status[${err.app_code || err.status}]`,
    message: err.responseText,
  };

  if (err.responseJSON) {
    if (err.responseJSON.error) {
      const responseError = err.responseJSON.error;

      error = {
        title: responseError.app_code,
        message: responseError.message || err.responseJSON.message || '',
      };
    } else {
      error = {
        title: 'Error',
        message: err.responseJSON.message || err.message,
      };
    }
  }

  return error;
};
