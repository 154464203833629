import { RequestArgs, Service, ServiceProviders } from 'store/types/api';
import { IServerError } from 'store/types/error';
import { errorHandler } from 'store/utils/error';
import { appConfig } from 'config/cv';
import { fetchRequest, getUserFromToken, ZdRequest } from './utils';

const zaf = appConfig.ZAF_CLIENT;

const services: {
  [ServiceProviders.ZAF]: Service;
  [ServiceProviders.MSF]: Service;
} = {
  [ServiceProviders.ZAF]: async (args: RequestArgs) => {
    const { url } = args;

    try {
      if (!zaf) {
        // If no ZD client, get the user info from jwt token
        if (url === 'currentUser') {
          return { data: { currentUser: getUserFromToken() } };
        } else {
          return { data: {} };
        }
      }

      const response = await zaf.get(url);

      return { data: response };
    } catch (error: unknown) {
      return { error: errorHandler(error as IServerError, args) };
    }
  },

  [ServiceProviders.MSF]: zaf ? ZdRequest : fetchRequest,
};

export const requestBuilder = ({
  serviceProvider,
}: {
  serviceProvider: ServiceProviders;
}) => services[serviceProvider];
