import zafClient from 'store/api/base/zafClient';

interface IMessage {
  type: 'AGENT360';
  data: object;
}

const useWindow = () => {
  // Open widescreen & send user and ticket data
  const openWindow: (args?: { data?: object; subPath?: string }) => void = (
    args
  ) => {
    const { data, subPath = '' } = args ?? {};

    const newWindow = window.open(
      `${window.location.origin}${window.location.pathname.replace(
        '/index.html',
        ''
      )}${subPath}`
    );

    if (newWindow) {
      if (window.widescreen) {
        // Allow having multiple tabs opened
        window.widescreen?.push(newWindow);
      } else {
        // Initialize the list with the first opened tab
        window.widescreen = [newWindow];
      }

      if (data) {
        newWindow.onload = () => {
          sendMessage(data);
        };
      }
    }
  };

  const closeWideScreen = () => {
    if (window.widescreen)
      window.widescreen.forEach((screen) => screen.close());
  };

  function addOnDesactivateWindowEventListener() {
    if (window.client) {
      window.client.on('app.deactivated', closeWideScreen);
    }
  }

  function removeOnDesactivateWindowEventListener() {
    if (window.client) {
      window.client.off('app.deactivated', closeWideScreen);
    }
  }

  // Data transfer between zendesk & widescreen
  function sendMessage(data?: object) {
    const message: IMessage = {
      type: 'AGENT360',
      data: data || {},
    };

    if (window.widescreen) {
      window.widescreen.forEach((screen) =>
        screen.postMessage(message, window.location.origin)
      );
    }

    if (window.opener) {
      window.opener.postMessage(message, window.location.origin);
    }
  }

  function addOnNewMessageEventListener(callback: (params: object) => void) {
    window.addEventListener('message', ({ data }) => {
      if (data.type && data.type === 'AGENT360') callback(data.data);
    });
    return () => {
      window.removeEventListener('message', callback);
    };
  }

  function getCurrentView() {
    if (!zafClient) return 'widescreen';

    return window.opener ? 'widescreen' : 'zendesk';
  }

  return {
    openWindow,
    addOnDesactivateWindowEventListener,
    removeOnDesactivateWindowEventListener,
    sendMessage,
    addOnNewMessageEventListener,
    getCurrentView,
    closeWideScreen,
  };
};

export default useWindow;
